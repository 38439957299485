import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col, Button } from "react-bootstrap"

import { ModalPortal, ToggleModal } from "../../portals/Modal.portal"
import TestRideModal from "../Navbar/Modals/TestRideModal"


export const fragment = graphql`
  fragment TestRideFormTriggerButtonFragment on WpPage_Flexlayouts_FlexibleLayouts_TestRideFormTriggerButton {
    buttonText
  }
`

const TestRideFormTriggerButton = ({ buttonText, variant = "primary", location }) => {
  const formId = "TEST_RIDE_DYNAMIC_TRIGGER_BUTTON"
  const formType = "BOOK_A_VISIT"
  return (
      <Container className="mb-4">
        <Row className="d-flex justify-content-center">
          <Col xs={11} lg={10} xl={9} className="d-flex flex-column">
              <ToggleModal
                formId={formId}
                formType={formType}
                location={location}
                toggle={show => {
                  return (
                    <div className="d-flex justify-content-center mb-3">
                      <Button variant={variant} size="lg" onClick={show}>
                        {buttonText}
                      </Button>
                    </div>
                  )
                }}
                content={hide => {
                  return (
                    <ModalPortal hide={hide} size="lg" centered={true}>
                      <TestRideModal location={location} formId={formId} formType={formType} />
                    </ModalPortal>
                  )
                }}
              />
          </Col>
        </Row>
      </Container>
  )
}

export default TestRideFormTriggerButton
